<template>
    <UserManagmentModal
        :value="value"
        :headline="$t('classMgmt.schoolSettingsHeader')"
        @closeDialogs="closeDialogs()"
    >
        <form v-if="value" @submit.prevent="updateSchool()">
            <v-card-text>
                <v-alert
                    :value="selectedSchool.typeId<4"
                    color="warning"
                    icon="warning"
                    outline
                >
                    {{$t('schoolMgmt.districtDate')}}
                </v-alert>
                <v-layout>
                    <v-flex xs8>
                        <v-select
                            v-model="startMonth"
                            :items="months"
                            item-text="text"
                            item-value="id"
                            :label="$t('schoolMgmt.startDate')"
                            :disabled="!allowEdits"
                        >
                            <template v-slot:prepend>
                                <v-tooltip right>
                                    <template v-slot:activator="{ on }">
                                        <v-icon color="info" v-on="on" class="text--lighten-2">info</v-icon>
                                    </template>
                                    <span>{{$t('classMgmt.usedInReports')}}<br/>{{$t('classMgmt.dateToConsider')}} <i>{{$t('classMgmt.thisSchoolYear')}}</i>.</span>
                                </v-tooltip>
                            </template>
                        </v-select>
                    </v-flex>
                    <v-flex xs4 class="ml-1"> 
                        <v-select
                            v-model="startDay"
                            :items="getDays(getDaysInMonth(startMonth))"
                            item-text="label"
                            item-value="id"
                            :disabled="!allowEdits"
                        />
                    </v-flex>
                </v-layout>
                <v-layout v-if="selectedSchool.typeId>3" wrap>
                    <v-flex xs12>
                        <v-select
                            v-model="defaultLanguage"
                            :items="languages"
                            item-text="label"
                            item-value="id"
                            :label="$t('schoolMgmt.defaultLang')"
                            :disabled="!allowEdits"
                        >
                            <template v-slot:prepend>
                                <v-tooltip right>
                                    <template v-slot:activator="{ on }">
                                        <v-icon color="info" v-on="on" class="text--lighten-2">info</v-icon>
                                    </template>
                                    <span>{{$t('classMgmt.changeDefaultLanguage')}}<br/>{{$t('classMgmt.doesNotAffectTeacher')}}</span>
                                </v-tooltip>
                            </template>
                        </v-select>
                    </v-flex>
                    <v-flex xs12 v-if="!isSelectedSchoolRostered && !isClassCodeEnabledSchoolGizmosOnly">
                        <v-checkbox
                            v-model="allowEnrollment"
                            primary
                            :disabled="!allowEdits"
                        >
                            <template v-slot:append>
                                <v-flex class="mt-1" :class="{'grey--text':!allowEdits}">{{$t('schoolMgmt.allowEnroll')}}</v-flex>
                                <v-tooltip top max-width="350">
                                    <template v-slot:activator="{ on }">
                                        <v-icon color="info" v-on="on" class="text--lighten-2 ml-2">info</v-icon>
                                    </template>
                                    <span>{{ allowEnrollMessage }}</span>                                    
                                </v-tooltip>   
                            </template>
                        </v-checkbox>
                    </v-flex>
                </v-layout>
            </v-card-text>
            <v-card-actions v-if="allowEdits">
                <v-flex class='pl-3 info--text'>
                    {{$t('schoolMgmt.waitTime')}}
                </v-flex>
                <v-spacer></v-spacer>
                <v-btn color="primary" class="elevation-0" type="submit">
                    <v-icon>check</v-icon>&nbsp;{{$t('classMgmt.updateSettingsButton')}}
                </v-btn>
            </v-card-actions>
        </form>
    </UserManagmentModal>
</template>

<script>
    import Constants from 'mixins/Constants'
    import UserManagmentModal from 'components/layout/UserManagmentModal'

	export default {
        name: 'SchoolSettingsModal',
        components: { UserManagmentModal },
        mixins: [Constants],
        props: {
            allowEdits: Boolean,
            isClassCodeEnabledSchoolGizmosOnly: Boolean,
            isClassCodeEnabledSchoolGizmosPlus: Boolean,
            isSelectedSchoolRostered: Boolean,
            selectedSchool: Object,
            value: Boolean,
        },
        data() {
            return {
                allowEnrollment: false,
                defaultLanguage: 0,
                startDay: 0,
                startMonth: 0,
            }
        },
		computed : {
             platform(){
                 return this.$store.state.platform
            },
            allowEnrollMessage() {
                return !this.isClassCodeEnabledSchoolGizmosPlus ? this.$t('schoolMgmt.allowEnrollExplained') : this.$t('schoolMgmt.allowEnrollExplained') + ' ' + this.$t('schoolMgmt.allowEnrollClassCodeModifier')
            }
        },
        methods: {
            closeDialogs() {
                this.$emit('closeDialogs')
            },
            async updateSchool(){//used in Admin.vue as well as ClassSettingsModal (moved there as well)
                let tenantId = this.tenantID
                let id = this.selectedSchool.id
                let typeId = this.selectedSchool.typeId
                let settings = {
                    defaultLanguageId: this.defaultLanguage,
                    allowStudentEnrollmentByTeachers: this.allowEnrollment
                }
                let startOfSchool = {
                    month: this.startMonth,
                    day: this.startDay
                }
                await this.platform.updateInstitution({tenantId, id, typeId, settings, startOfSchool})
                await this.$emit("updateModel", id)
                this.$emit("handleSuccess", 'Settings Updated')
                this.$emit("closeDialogs")
            },
        },
        watch: {
            value(val) {
                if (val) {
                    this.startMonth = this.selectedSchool.startOfSchool.month
                    this.startDay = this.selectedSchool.startOfSchool.day
                    this.defaultLanguage = this.selectedSchool.settings.defaultLanguageId
                    this.allowEnrollment = this.selectedSchool.settings.allowStudentEnrollmentByTeachers
                }
            }
        }
	}
</script>

<style lang="less" scoped>
</style>